.container{
    display: flex;
    margin: auto;
    width: 300px;
    align-items: center;
    text-align: center;
    margin-top: 70px;
    border-radius: 35px;
    background-color: white;
    flex-direction: column;
}

.logo{
    animation: spin 1s infinite;
}

@keyframes spin {
    0%{
        transform: rotateZ(0deg);
    }
    100%{
        transform: rotateZ(360deg);
    }
}