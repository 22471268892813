.cardcontainer{
    width: 80%;
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
}

.buttoncontainer{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.button{
    width: 50%;
    align-items: center;
    background-color: yellow;
    color: black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
    font-family: 'Poppins';
    display: flex;
    margin: auto;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
    justify-content: center;
}

.button:hover{
    background-color: black;
    color: yellow;
}

@media screen and (max-width: 480px) {
    
    .cardcontainer{
        display: flex;
        margin: auto;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20;
    }
}