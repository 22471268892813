
.container{
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 65px;
}

.header{
    width: 50%;
    display: flex;
    color: white;
    justify-content: center;
}

.buttonDiv{
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.button{
    padding: 10px;
    font-weight: 600;
    font-family: 'Poppins';
    border-radius: 30px;
    width: 150px;

    background-color: yellow;
    font-size: 20px;
}

.imgDiv{
    width: 15%;
}

.img{
    height: 70px;
    margin-left: 20px;
}

@media screen and (max-width: 480px) {
    .header{
        display: none;
    }
    .container{
        width: 70%;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    }
    .buttonDiv{
        display: flex;
        justify-content: center;
        width: 40%;
    }
    
    .button{
        padding: 10px;
        font-weight: 600;
        font-family: 'Poppins';
        border-radius: 30px;
        width: 130px;
    
        background-color: yellow;
        font-size: 20px;
    }
    
    .imgDiv{
        width: 100px;
        display: flex;
        justify-content: flex-start;
    }
    
    .img{
        height: 70px;
    }

}

@media screen and (max-width: 1000px) {
    .header{
        display: flex;
    }
    .container{
        width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 65px;
    }
    .buttonDiv{
        display: flex;
        justify-content: flex-end;
        width: 77%;
    }
    
    .button{
        padding: 10px;
        font-weight: 600;
        font-family: 'Poppins';
        border-radius: 30px;
        width: 150px;
        margin-right: 25px;
        background-color: yellow;
        font-size: 20px;
    }
    
    .imgDiv{
        width: 10%;
        display: flex;
        justify-content: flex-start;
    }
    
    .img{
        height: 60px;
    }

}

@media screen and (max-width: 760px) {
    .header{
        display: none;
    }
}