.div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 10px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: linear-gradient(145deg, rgb(250, 250, 250), rgb(247, 247, 247));
  border-radius: 25px;
  width: 100%;
  box-shadow: 2px 2px 5px rgb(21, 21, 186), -2px -2px 5px rgb(21, 21, 186);
}

.label {
  color: blue;
  font-weight: 600;
  font-family: "Poppins";
}

.input {
  color: blue;
  font-weight: 500;
  font-family: "Poppins";
  text-align: right;
  margin-left: 15px;
  background-color: rgb(248, 255, 115);
  font-size: 14px;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 1px 1px 1px black, -1px -1px 1px black;
  padding: 7px;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width:480px) {
  
  .input{
    width: 50%;
  }

  .label{
    font-weight: 600;
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .input{
    width: 50%;
  }
}
