

.container{
    width: 75%;
    padding: 35px;
    position: relative;
    margin: auto;
    margin-top: 35px;
    display: flex;
    overflow-x: 1000px;
    margin-bottom: 45px;
    overflow-y: hidden;
    text-align: center;
    justify-content: center;
}

.table{
    margin: auto;
    overflow: scroll;
    color: white;
    padding: 15px;
    justify-content: center;
    text-align: center;
}

.button{
    background-color: yellow;
    color: black;
    border-radius: 35px;
    width: 135px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.25px;
}

.titleclass{
    text-align: left;
    padding: 7px;
    letter-spacing: 1.35px;
}

.row{
    margin-top: 20px;
    font-size: 18px;
}

.headerclass{
    letter-spacing: 1.5px;
}

@media screen and (max-width: 1000px) {
    

.container{
    width: 80%;
    padding: 35px;
    position: relative;
    margin: auto;
    flex-direction: column;
    margin-top: 35px;
    display: flex;
    overflow-x: 1000px;
    margin-bottom: 45px;
    overflow-y: hidden;
    text-align: center;
    justify-content: center;
}

.table{
    margin: auto;
    overflow: scroll;
    color: white;
    padding: 15px;
    justify-content: center;
    text-align: center;
}

.button{
    background-color: yellow;
    color: black;
    border-radius: 35px;
    width: 85px;
    font-size: 16px;
    height: 45px;
    font-weight: 600;
    letter-spacing: 1.25px;
}

.titleclass{
    text-align: left;
    padding: 7px;
    letter-spacing: 1.35px;
}

.row{
    margin-top: 20px;
    font-size: 16px;
}

.headerclass{
    letter-spacing: 1.5px;
}

.scroll{
    display: flex;
    margin: auto;
    color: white;
}

}


@media screen and (max-width: 480px) {
    

    .container{
        width: 80%;
        padding: 35px;
        position: relative;
        margin: auto;
        margin-top: 35px;
        display: flex;
        overflow-x: 1000px;
        margin-bottom: 45px;
        overflow-y: hidden;
        text-align: center;
        justify-content: center;
    }
    
    .table{
        margin: auto;
        overflow: scroll;
        color: white;
        padding: 15px;
        justify-content: center;
        text-align: center;
    }
    
    .button{
        background-color: yellow;
        color: black;
        border-radius: 35px;
        width: 75px;
        font-size: 12px;
        height: 35px;
        font-weight: 600;
        letter-spacing: 1.25px;
    }
    
    .titleclass{
        text-align: left;
        padding: 7px;
        letter-spacing: 1.35px;
    }
    
    .row{
        margin-top: 20px;
        font-size: 10px;
    }
    
    .headerclass{
        letter-spacing: 1.5px;
        font-size: 12px;
    }
    
    }