.headerdiv{
    display: flex;
    margin: auto;
    justify-content: space-evenly;
    align-items: flex-start;
}

.icon{
    width: 20px;
    height: 20px;
    color: black;
    background-color: rgb(201, 201, 201);
    border: 2.3px solid black;
    box-shadow: 1px 1px 3px black;
    padding: 10px;
    margin-right: -25px;
    border-radius: 10px;
}

.icon:hover{
    scale: 1.05;
}

.h2{
    color: blue;
    font-weight: 700;
    text-align: center;
    font-size: 24px;
    width: 75%;
    margin-left: 35px;
}

.ul{
    color: blue;
    font-weight: 500;
    text-align: left;
    font-size: 17px;
}

.hr{
    border: .5px solid blue;
    width: 80%;
}
