@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.Nav_nav__1xXf6{
    width: 100%;
   display: flex;
   margin: auto;
   flex-direction: column;
   align-items: center;
   padding: 10px;
}

.Nav_ul__edL7T{
    list-style: none;
    display: flex;
    margin: auto;
    margin-left: 5px;
    justify-content: flex-end;
}

.Nav_li__1BvQM{
    color: white;
    text-decoration: none;
    font-size: 20px;
    padding: 5px;
    margin: 7px;
    font-weight: 500;
}

.Nav_li__1BvQM:hover{
    background-color: white;
    color: blue;
    border-radius: 15px;
    padding: 5px;
}

.Nav_li__1BvQM:active{
    color: black;
}

@media screen and (max-width: 480px) {
    
    .Nav_nav__1xXf6{
        width: 95%;
    }
}

.MobileNav_ul__30_Uo{
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    margin-top: -25px;
    padding: 5px;
    width: 50%;
}

.MobileNav_li__3w3Co{
    padding: 10px;
    margin-top: 5px;
}
.NavBar_container__3168D{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    background-color: rgb(0, 0, 255);
    box-shadow: 5px 5px 11px rgb(37, 37, 222), -5px -5px 11px rgb(37, 37, 222);
    margin-bottom: 30px;
}

.NavBar_button__1Isuj{
    width: 50px;
    margin-right: 50px;
    box-shadow: .2px .2px 1px rgb(0, 32, 135), -.2px -.2px 1px rgb(0, 32, 135);
    border-radius: 10px;
    align-items: center;
    background-color: yellow;
    cursor: pointer;
}

.NavBar_icon__gMbu0{
    height: 30px;
}

@media screen and (max-width: 480px) {
    
    .NavBar_container__3168D{
        width: 95%;
    }

    .NavBar_button__1Isuj{
        margin-right: 10px;
    }
}
.Card_div__2c3zf{
    box-shadow: 5px 5px 11px rgb(0, 0, 186), -5px -5px 11px rgb(0, 0, 186);
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 25px;
}
.Input_div__25Y-p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 10px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: linear-gradient(145deg, rgb(250, 250, 250), rgb(247, 247, 247));
  border-radius: 25px;
  width: 100%;
  box-shadow: 2px 2px 5px rgb(21, 21, 186), -2px -2px 5px rgb(21, 21, 186);
}

.Input_label__3pyn- {
  color: blue;
  font-weight: 600;
  font-family: "Poppins";
}

.Input_input__3M-uD {
  color: blue;
  font-weight: 500;
  font-family: "Poppins";
  text-align: right;
  margin-left: 15px;
  background-color: rgb(248, 255, 115);
  font-size: 14px;
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 1px 1px 1px black, -1px -1px 1px black;
  padding: 7px;
}

/* Chrome, Safari, Edge, Opera */
.Input_input__3M-uD::-webkit-outer-spin-button,
.Input_input__3M-uD::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.Input_input__3M-uD[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width:480px) {
  
  .Input_input__3M-uD{
    width: 50%;
  }

  .Input_label__3pyn-{
    font-weight: 600;
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .Input_input__3M-uD{
    width: 50%;
  }
}

.CNiphtyProphet_container__2-R_l{
    width: 45%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 25px;
    background-color: white;
    border: .3px solid black;
    box-shadow: 1px 1px 1px black, -1px -1px 1px black;
}

.CNiphtyProphet_wrapper__P47H-{
    display: none;
}

.CNiphtyProphet_header__2uh_Y{
    color: blue;
    display: flex;
    margin: auto;
    flex-direction: column;
    font-size: 20px;
    justify-content: center;
  }

  .CNiphtyProphet_headerprocess__XkkRq{
    color: blue;
    display: flex;
    margin: auto;
    text-align: center;
    font-size: 28px;
    justify-content: center;
  }

  .CNiphtyProphet_hr__2klws{
    width: 80%;
    border: 1px solid blue;
    margin-top: -15px;
    margin-bottom: 32px;
  }

.CNiphtyProphet_buttonProcessContainer__1mIkV{
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
}

.CNiphtyProphet_button__3qvXX{
    background-color: yellow;
    color: black;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.2px;
    border-radius: 35px;
    height: 70px;
    margin: 10px;
    font-family: 'Poppins';
    width: 30%;
    border: none;
    cursor: pointer;
    box-shadow: 1px 1px 4px black, -1px -1px 4px black;
}

.CNiphtyProphet_button__3qvXX:hover{
    background-color: black;
    color: yellow;
    box-shadow: 3px 3px 4px yellow, -3px -3px 4px yellow;

}

.CNiphtyProphet_buttoncontainer__32Scx{
    display: flex;
    margin: auto;
    justify-content: space-around;
    width: 90%;
}

.CNiphtyProphet_buttonprocess__i-wKp{
    background-color: yellow;
    color: black;
    border-radius: 30px;
    font-size: 20px;
    font-family: 'Poppins';
    border: 3px solid black;
    font-weight: 600;
    cursor: pointer;
}

.CNiphtyProphet_buttonprocess__i-wKp:hover{
    background-color: black;
    color: yellow;
}

  .CNiphtyProphet_inputscontainer__1YsKT{
    display: flex;
    margin: auto;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 25px;
    width: 100%;
  }

  .CNiphtyProphet_dropdowncontainer__1624N{
    display: flex;
    margin: auto;
    justify-content: center;
    margin-top: 35px;
  }

  .CNiphtyProphet_input__3Gb1h{
    color: white;
    font-family: "Poppins";
    text-align: right;
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
    background: linear-gradient(145deg, rgb(0, 94, 255), rgb(27, 84, 255));
    border: 1px solid black;
    border-radius: 12px;
    box-shadow: 1px 1px 1px black, -1px -1px 1px black;
    padding: 7px;
}

/* Chrome, Safari, Edge, Opera */
.CNiphtyProphet_input__3Gb1h::-webkit-outer-spin-button,
.CNiphtyProphet_input__3Gb1h::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.CNiphtyProphet_input__3Gb1h[type=number] {
  -moz-appearance: textfield;
}

.CNiphtyProphet_h4__GfDNg{
  display: flex;
  margin: auto;
  justify-content: flex-start;
  color: blue;
  font-family: 'Poppins';
}

  @media screen and (max-width: 1000px){
    .CNiphtyProphet_container__2-R_l{
      width: 72%;
      margin: auto;
      display: flex;
  }

  .CNiphtyProphet_header__2uh_Y{
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  
  .CNiphtyProphet_wrapper__P47H-{
      display: none;
  }

    .CNiphtyProphet_inputscontainer__1YsKT{
      display: flex;
      margin: auto;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 25px;
      width: 100%;
    }
  
    .CNiphtyProphet_input__3Gb1h{
      color: white;
      font-family: "Poppins";
      text-align: right;
      margin-left: 15px;
      font-weight: 500;
      font-size: 14px;
      background: linear-gradient(145deg, rgb(0, 94, 255), rgb(27, 84, 255));
      border: 1px solid black;
      border-radius: 12px;
      box-shadow: 1px 1px 1px black, -1px -1px 1px black;
      padding: 7px;
      width: 50%;
  }
  
  .CNiphtyProphet_button__3qvXX{
    width: 50%;
  }

  .CNiphtyProphet_buttonprocess__i-wKp{
    margin: 10px
  }
  }

  @media screen and (max-width: 480px) {
    .CNiphtyProphet_container__2-R_l{
        width: 72%;
        margin: auto;
        display: flex;
    }

    .CNiphtyProphet_header__2uh_Y{
      text-align: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin: auto;
    }
    
    .CNiphtyProphet_wrapper__P47H-{
        display: none;
    }

      .CNiphtyProphet_inputscontainer__1YsKT{
        display: flex;
        margin: auto;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 25px;
        width: 100%;
      }
    
      .CNiphtyProphet_input__3Gb1h{
        color: white;
        font-family: "Poppins";
        text-align: right;
        margin-left: 15px;
        font-weight: 500;
        font-size: 14px;
        background: linear-gradient(145deg, rgb(0, 94, 255), rgb(27, 84, 255));
        border: 1px solid black;
        border-radius: 12px;
        box-shadow: 1px 1px 1px black, -1px -1px 1px black;
        padding: 7px;
        width: 50%;
    }
    
    .CNiphtyProphet_button__3qvXX{
      width: 50%;
    }

    .CNiphtyProphet_buttonprocess__i-wKp{
      margin: 10px
    }
}

.ProcessHeader_headerdiv__2d4Eo{
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    color: blue;
}

.ProcessHeader_h2__fU_N5{
}

.ProcessHeader_h4__SKYgV{
    margin: 0;
}
.CNFT_header__2XkOx{
    color: white;
}

.CNFT_width__2NqkE{
    width: 60%;
}


.Dropdown_selectOptionsDiv__23dP6{
    background-color: white;
    display: block;
    border-radius: 15px;
    width: 50%;
  }
  
  .Dropdown_selectOptionsOption__2s7lA{
    display: none;
    text-align: left;
  }
  
  .Dropdown_selectOptionsOption__2s7lA:checked + .Dropdown_selectOptionsText__EflCD {
    display: block;
    text-align: left;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: 600;
    border-radius: 15px;
    font-size: 16px;
    padding: 10px;
  }
  
  .Dropdown_selectOptionsText__EflCD {
    display: none;
    width: 100%;
    margin: 0;
    font-weight: 600;
    padding: 15px;
    color: yellow;
    background-color: black;
  }
  
  .Dropdown_selectOptionsContainer__1xhw7 {
    display: block;
    background-color: black;
    font-size: 16px;
    font-weight: 600;
    border-radius: 15px;
    margin-top: -8px;
    color: black;
  }
  
  .Dropdown_selectOptionsDivBox__2bxnS{
    position: relative;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 15px;
    outline: none;
  }
  
  .Dropdown_selectOptionsDivBox__2bxnS:focus + .Dropdown_selectList__20h2G {
    opacity: 1;
    -webkit-animation-name: Dropdown_none__1ZqsS;
    animation-name: Dropdown_none__1ZqsS;
  }
  
  .Dropdown_selectOptionsDivBox__2bxnS:focus + .Dropdown_selectList__20h2G .Dropdown_selectBox__1YuYv {
    cursor: pointer;
  }
  
  .Dropdown_selectOptionsDivBox__2bxnS:focus .Dropdown_selectIcon__3vaaG{
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
  
  .Dropdown_selectIcon__3vaaG{
    position: absolute;
    top: 50%;
    right: 5px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 26px;
    padding: 5px;
    border-radius: 10px;
    background-color: white;
    transition: 0.2s ease;
  }
  
  .Dropdown_listContainer__3PJq4{
    height: 300px;
    position: relative;
  }
  
  .Dropdown_selectList__20h2G{
    position: absolute;
    width: 30%;
    overflow: auto;
    max-height: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    -webkit-animation-name: Dropdown_HideList__Wglo9;
    animation-name: Dropdown_HideList__Wglo9;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: step-start;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  }
  
  .Dropdown_selectBox__1YuYv {
    display: block;
    padding: 20px;
    background-color: #fff;
  }
  
  .Dropdown_selectBox__1YuYv:hover,
  .Dropdown_selectBox__1YuYv:focus {
    color: black;
    background-color: yellow;
  }
  
  .Dropdown_selectOptionsContainer__1xhw7:focus {
    color: black;
    background-color: yellow;
  }
  
  @-webkit-keyframes Dropdown_HideList__Wglo9 {
    from {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }
    to {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
    }
  }
  
  @keyframes Dropdown_HideList__Wglo9 {
    from {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }
    to {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
    }
  }

@media screen and (max-width: 480px) {
  
  .Dropdown_selectList__20h2G{
    width: 50%;
  }
}

.Crypto_header__2objO{
    color: white;
}

.Crypto_width__3GJDM{
    width: 60%;
}

.Loading_container__rN4zk{
    display: flex;
    margin: auto;
    width: 300px;
    align-items: center;
    text-align: center;
    margin-top: 70px;
    border-radius: 35px;
    background-color: white;
    flex-direction: column;
}

.Loading_logo__Gsv_a{
    -webkit-animation: Loading_spin__2MW_s 1s infinite;
            animation: Loading_spin__2MW_s 1s infinite;
}

@-webkit-keyframes Loading_spin__2MW_s {
    0%{
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
    100%{
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@keyframes Loading_spin__2MW_s {
    0%{
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
    100%{
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}
.SummaryAnalysis_header__Ywg-K{
    text-align: center;
    color: blue;
}

.SummaryAnalysis_div__GzrCS{
    display: flex;
    margin: auto;
    width: 90%;
    flex-wrap: wrap;
    color: blue;
}

.SummaryAnalysis_buttoncontainer__3c2m_{
    display: flex;
    margin: auto;
    justify-content: center;
}

.SummaryAnalysis_button__2Nu-G{
    background-color: yellow;
    color: black;
    border-radius: 30px;
    font-weight: 600;
    font-size: 20px;
    border: 3px solid black;
    cursor: pointer;
}

.SummaryAnalysis_button__2Nu-G:hover{
    background-color: black;
    color: yellow;
}

.SummaryAnalysis_summarydiv__1O5Mg{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    border-radius: 20px;
    color: white;
    padding: 17px;
    width: 100%;
    margin-bottom: 25px;
    background-color: blue;
    box-shadow: 4px 4px 10px rgb(51, 51, 255), -4px -4px 10px rgb(51, 51, 255);
}

.SummaryAnalysis_h3header__F83bE{
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: auto;
}

.SummaryAnalysis_hr__RLGTf{
    width: 75%;
    border: .5px solid white;
}

@media screen and (max-width: 480px) {
    
    .SummaryAnalysis_summarydiv__1O5Mg{
        padding: 5%;
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .SummaryAnalysis_h3header__F83bE{
        font-size: 18px;
    }
}
.SummaryAnalysisItem_div__1bxL6{
    display: flex;
    margin: auto;
    justify-content: flex-start;
    width: 100%;
}

.SummaryAnalysisItem_h3__1a_QC{
    width: 65%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
}

.SummaryAnalysisItem_p__7pRQV{
    font-weight: 500;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (max-width: 480px) {
    
    .SummaryAnalysisItem_h3__1a_QC{
        font-size: 14px;
    }

    .SummaryAnalysisItem_p__7pRQV{
        font-size: 14px;
    }
}

.CNFTData_img__Mlg1f{
    height: auto;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    margin-bottom: 3px;
    width: 250px;
    color: white;
} 

.CNFTData_container__116IE{
    width: 80%;
    flex-direction: column;
    margin: auto;
    display: flex;
    align-items: center;
}

.CNFTData_wrapper__1xULP{
    display: none;
}

.CNFTData_row__2Bod3{
    display: flex;
    margin: auto;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.CNFTData_ul__2kdXZ{
    list-style: none;
    margin: 25px;
    margin-left: -40px;
}

.CNFTData_span__1w1fH{
    font-weight: 600;
    margin: 10px;
    letter-spacing: 1.2px;
}

.CNFTData_li__2FHPw{
    color: white;
    margin: 17px;
}

.CNFTData_width__1omRU{
    width: 100%;
    margin-bottom: 20px;
  }

  .CNFTData_thanks__ylPNu{
    margin-top: 15px;
    color: white;
    font-weight: 600;

  }

  .CNFTData_dataContainer__1EwcU{
    display: flex;
    width: 90%; 
    margin: auto; 
    justify-content: center; 
}

.CNFTData_logoImg__2rcUo{
    width: 175px;
    -webkit-animation: none;
            animation: none;
}

.CNFTData_loadContainer__3bZlg{
    width: 250px;
    margin-top: -15px;
}

.CNFTData_none__3adFF{
    display: none;
}

.CNFTData_p__51QeL{
    color: white;
    font-weight: 600;
    letter-spacing: 1.35px;
    font-size: 22px;
}

@media screen and (max-width: 480px) {
    .CNFTData_row__2Bod3{
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }

    .CNFTData_container__116IE{
        width: 100%;
        padding: 0;
    }

    .CNFTData_dataContainer__1EwcU{
        width: 100%;
        margin-top: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .CNFTData_row__2Bod3{
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }

    .CNFTData_container__116IE{
        width: 100%;
        padding: 0;
    }

    .CNFTData_dataContainer__1EwcU{
        width: 100%;
        margin-top: 20px;
    }

    .CNFTData_thanks__ylPNu{
        padding: 15px;
        font-size: 16px;
        text-align: center;
    }
}
.CryptoData_container__3OWMp{
    display: flex;
    width: 80%;
    color: white;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 50px;
}

.CryptoData_cardano-ecosystem__1Bkz4 {
    justify-content: center;
    background: linear-gradient(145deg, rgb(19, 19, 230), rgb(12, 12, 226));
    margin: auto;
    display: flex;
    box-shadow: 9px 9px 11px rgb(21, 21, 186), -9px 0 11px rgb(21, 21, 186);
    margin-bottom: 80px;
    border-top: 5px solid blue;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  
  .CryptoData_cardano-data-div__2K_Ep {
    color: white;
    width: 88%;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 25px;
    align-items: center;
  }
  
  .CryptoData_cardanotable__3Jk-6 {
    width: 95%;
    margin: auto;
    border-radius: 25px;
    background: linear-gradient(145deg, rgb(5, 5, 220), rgb(8, 8, 216));
    box-shadow: 9px 9px 11px rgb(21, 21, 186), -9px 0 11px rgb(21, 21, 186);
  }
  
  .CryptoData_coin-symbol__3Ct4u {
    height: 50px;
  }
  
  tr,
  td,
  th {
  }
  
  th {
    height: 50px;
    text-align: center;
  }
  
  td {
    font-weight: 500;
    text-align: center;
  }
  
.CryptoData_wrapper__2kDIl{
  display: none;
}

.CryptoData_width__2LaSK{
  width: 60%;
  margin-bottom: 22px;
}
  
  .CryptoData_coinsymbol__y5Y3G {
    margin-top: 9px;
    border-radius: 50%;
  }
  
  .CryptoData_projects__15uIv {
    background-color: yellow;
  }
  
  button {
    width: 150px;
    height: 50px;
  }

  #CryptoData_id__1Uoiw{
    margin-bottom: 25px;
  }

  .CryptoData_thanks__3SZcr{
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    .CryptoData_container__3OWMp{
      display: flex;
      width: 70%;
      color: white;
      overflow-x: auto;
      overflow-y: hidden;
      flex-direction: column;
      align-items: center;
      margin: auto;
      padding: 40px;
      margin-top: 50px;
  }
  
  .CryptoData_cardano-ecosystem__1Bkz4 {
      justify-content: center;
      background: linear-gradient(145deg, rgb(19, 19, 230), rgb(12, 12, 226));
      margin: auto;
      display: flex;
      box-shadow: 9px 9px 11px rgb(21, 21, 186), -9px 0 11px rgb(21, 21, 186);
      margin-bottom: 80px;
      border-top: 5px solid blue;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    
    .CryptoData_cardano-data-div__2K_Ep {
      color: white;
      width: 98%;
      flex-direction: column;
      margin: auto;
      justify-content: center;
      display: flex;
      margin-bottom: 25px;
      align-items: center;
    }
    
    .CryptoData_cardanotable__3Jk-6 {
      width: 90%;
      padding: 10px;
      overflow: scroll;
      justify-content: center;
      margin: auto;
      border-radius: 25px;
      background: linear-gradient(145deg, rgb(5, 5, 220), rgb(8, 8, 216));
      box-shadow: 9px 9px 11px rgb(21, 21, 186), -9px 0 11px rgb(21, 21, 186);
    }
    
    .CryptoData_coin-symbol__3Ct4u {
      height: 50px;
    }
    
    tr,
    td,
    th {
    }
    
    th {
      height: 50px;
      text-align: center;
    }
    
    td {
      font-weight: 500;
      text-align: center;
    }
    
  .CryptoData_wrapper__2kDIl{
    display: none;
  }
  
  .CryptoData_width__2LaSK{
    width: 90%;
    margin-bottom: 22px;
  }
    
    .CryptoData_coinsymbol__y5Y3G {
      margin-top: 9px;
      border-radius: 50%;
    }
  
    #CryptoData_id__1Uoiw{
      margin-bottom: 25px;
    }
  
    .CryptoData_thanks__3SZcr{
      text-align: center;
    }
  }

  @media screen and (max-width: 1000px) {
    
    .CryptoData_container__3OWMp{
      display: flex;
      width: 70%;
      color: white;
      overflow-x: auto;
      overflow-y: hidden;
      flex-direction: column;
      align-items: center;
      margin: auto;
      padding: 40px;
      margin-top: 50px;
  }
  
  .CryptoData_cardano-ecosystem__1Bkz4 {
      justify-content: center;
      background: linear-gradient(145deg, rgb(19, 19, 230), rgb(12, 12, 226));
      margin: auto;
      display: flex;
      box-shadow: 9px 9px 11px rgb(21, 21, 186), -9px 0 11px rgb(21, 21, 186);
      margin-bottom: 80px;
      border-top: 5px solid blue;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    
    .CryptoData_cardano-data-div__2K_Ep {
      color: white;
      width: 98%;
      flex-direction: column;
      margin: auto;
      justify-content: center;
      display: flex;
      margin-bottom: 25px;
      align-items: center;
    }
    
    .CryptoData_cardanotable__3Jk-6 {
      width: 90%;
      padding: 10px;
      overflow: scroll;
      justify-content: center;
      margin: auto;
      border-radius: 25px;
      background: linear-gradient(145deg, rgb(5, 5, 220), rgb(8, 8, 216));
      box-shadow: 9px 9px 11px rgb(21, 21, 186), -9px 0 11px rgb(21, 21, 186);
    }
    
    .CryptoData_coin-symbol__3Ct4u {
      height: 50px;
    }
    
    tr,
    td,
    th {
    }
    
    th {
      height: 50px;
      text-align: center;
    }
    
    td {
      font-weight: 500;
      text-align: center;
    }
    
  .CryptoData_wrapper__2kDIl{
    display: none;
  }
  
  .CryptoData_width__2LaSK{
    width: 90%;
    margin-bottom: 22px;
  }
    
    .CryptoData_coinsymbol__y5Y3G {
      margin-top: 9px;
      border-radius: 50%;
    }
  
    #CryptoData_id__1Uoiw{
      margin-bottom: 25px;
    }
  
    .CryptoData_thanks__3SZcr{
      text-align: center;
      font-size: 16px;
      padding: 5px;
    }
  }
.Data_cardcontainer__3vapK{
    width: 80%;
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
}

.Data_buttoncontainer__121R4{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.Data_button__3VaX5{
    width: 50%;
    align-items: center;
    background-color: yellow;
    color: black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
    font-family: 'Poppins';
    display: flex;
    margin: auto;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
    justify-content: center;
}

.Data_button__3VaX5:hover{
    background-color: black;
    color: yellow;
}

@media screen and (max-width: 480px) {
    
    .Data_cardcontainer__3vapK{
        display: flex;
        margin: auto;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20;
    }
}


.Plans_container__1Bjfh{
    width: 75%;
    padding: 35px;
    position: relative;
    margin: auto;
    margin-top: 35px;
    display: flex;
    overflow-x: 1000px;
    margin-bottom: 45px;
    overflow-y: hidden;
    text-align: center;
    justify-content: center;
}

.Plans_table__2b3Pq{
    margin: auto;
    overflow: scroll;
    color: white;
    padding: 15px;
    justify-content: center;
    text-align: center;
}

.Plans_button__22nN0{
    background-color: yellow;
    color: black;
    border-radius: 35px;
    width: 135px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.25px;
}

.Plans_titleclass__3HRqG{
    text-align: left;
    padding: 7px;
    letter-spacing: 1.35px;
}

.Plans_row__uGQgP{
    margin-top: 20px;
    font-size: 18px;
}

.Plans_headerclass__3yRFL{
    letter-spacing: 1.5px;
}

@media screen and (max-width: 1000px) {
    

.Plans_container__1Bjfh{
    width: 80%;
    padding: 35px;
    position: relative;
    margin: auto;
    flex-direction: column;
    margin-top: 35px;
    display: flex;
    overflow-x: 1000px;
    margin-bottom: 45px;
    overflow-y: hidden;
    text-align: center;
    justify-content: center;
}

.Plans_table__2b3Pq{
    margin: auto;
    overflow: scroll;
    color: white;
    padding: 15px;
    justify-content: center;
    text-align: center;
}

.Plans_button__22nN0{
    background-color: yellow;
    color: black;
    border-radius: 35px;
    width: 85px;
    font-size: 16px;
    height: 45px;
    font-weight: 600;
    letter-spacing: 1.25px;
}

.Plans_titleclass__3HRqG{
    text-align: left;
    padding: 7px;
    letter-spacing: 1.35px;
}

.Plans_row__uGQgP{
    margin-top: 20px;
    font-size: 16px;
}

.Plans_headerclass__3yRFL{
    letter-spacing: 1.5px;
}

.Plans_scroll__14Y0y{
    display: flex;
    margin: auto;
    color: white;
}

}


@media screen and (max-width: 480px) {
    

    .Plans_container__1Bjfh{
        width: 80%;
        padding: 35px;
        position: relative;
        margin: auto;
        margin-top: 35px;
        display: flex;
        overflow-x: 1000px;
        margin-bottom: 45px;
        overflow-y: hidden;
        text-align: center;
        justify-content: center;
    }
    
    .Plans_table__2b3Pq{
        margin: auto;
        overflow: scroll;
        color: white;
        padding: 15px;
        justify-content: center;
        text-align: center;
    }
    
    .Plans_button__22nN0{
        background-color: yellow;
        color: black;
        border-radius: 35px;
        width: 75px;
        font-size: 12px;
        height: 35px;
        font-weight: 600;
        letter-spacing: 1.25px;
    }
    
    .Plans_titleclass__3HRqG{
        text-align: left;
        padding: 7px;
        letter-spacing: 1.35px;
    }
    
    .Plans_row__uGQgP{
        margin-top: 20px;
        font-size: 10px;
    }
    
    .Plans_headerclass__3yRFL{
        letter-spacing: 1.5px;
        font-size: 12px;
    }
    
    }

body{
  font-family: 'Poppins';
  font-weight: 300;
  background-color: blue;
  margin: 0;
}


.HomeList_headerdiv__2KBOP{
    display: flex;
    margin: auto;
    justify-content: space-evenly;
    align-items: flex-start;
}

.HomeList_icon__1coyX{
    width: 20px;
    height: 20px;
    color: black;
    background-color: rgb(201, 201, 201);
    border: 2.3px solid black;
    box-shadow: 1px 1px 3px black;
    padding: 10px;
    margin-right: -25px;
    border-radius: 10px;
}

.HomeList_icon__1coyX:hover{
    scale: 1.05;
}

.HomeList_h2__2TZFW{
    color: blue;
    font-weight: 700;
    text-align: center;
    font-size: 24px;
    width: 75%;
    margin-left: 35px;
}

.HomeList_ul__1KWSP{
    color: blue;
    font-weight: 500;
    text-align: left;
    font-size: 17px;
}

.HomeList_hr__2vuya{
    border: .5px solid blue;
    width: 80%;
}

.Modal_backdrop__37XlC {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .Modal_modal__2UfCb {
    position: fixed;
    top: 20vh;
    margin-left: -15px;
    left: 5%;
    width: 90%;
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    -webkit-animation: Modal_slide-down___VtaL 300ms ease-out forwards;
            animation: Modal_slide-down___VtaL 300ms ease-out forwards;
  }

  .Modal_none__3Uh99{
    display: none;
  }
  
  @media (min-width: 768px) {
    .Modal_modal__2UfCb {
      width: 40rem;
      left: calc(50% - 20rem);
    }
  }
  
  @-webkit-keyframes Modal_slide-down___VtaL {
    from {
      opacity: 0;
      -webkit-transform: translateY(-3rem);
              transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  
  @keyframes Modal_slide-down___VtaL {
    from {
      opacity: 0;
      -webkit-transform: translateY(-3rem);
              transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
.Home_header__1pDDY{
    color: white;
    font-size: 54px;
    letter-spacing: 1.25px;
    width: 75%;
    justify-content: center;
    display: flex;
    margin: auto;
    text-align: center;
}

.Home_cardcontainer__2v4eT{
    background-color: blue;
    border-radius: 15px;
    width: 480px;
    margin: 25px;
}

.Home_buttonPage__uz4CQ{
    background-color: blue;
    color: white;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 10px;
    font-size: 20px;
    width: 160px;
    height: 55px;
    border-radius: 35px;
    cursor: pointer;
}

.Home_buttonPage__uz4CQ:hover{
    scale: 1.03;
}

.Home_detailsButton__16Lnf{
    border-radius: 15px;
    background-color: black;
    color: yellow;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 1.3px;
    margin: 10px;
    width: 195px;
    height: 65px;
    font-size: 19px;
    cursor: pointer;
} 

.Home_detailsButton__16Lnf:hover{
    scale: 1.03;
}

.Home_container__1H1vc{
    width: 90%;
    display: flex;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: none;
}

.Home_iconcontainer__mBnNV{
    width: 50px;
    display: flex;
    margin: 5px;
    justify-content: center;
}

.Home_iconcontainer__mBnNV img{
    margin: 9px;
    cursor: pointer;
}

.Home_iconcontainer__mBnNV img:hover{
    scale: 1.11;
}

.Home_list__1ZXR_{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 290px;
    font-size: 21px;
    margin: auto;
    color: blue;
    letter-spacing: 1.35px;
    background-color: yellow;
    border-radius: 10px;
    box-shadow: 2px 2px 10px yellow, -2px -2px 10px yellow;
}

.Home_list__1ZXR_:hover{
    scale: 1.03;
}

.Home_none__Y5pFy{
    display: none;
}


.Home_h2__3TJMY{
    text-align: center;
    font-size: 24px;
    letter-spacing: 1.2px;
}

.Home_li__25pqC{
    text-align: left;
    margin: 7px;
}

.Home_a__2Y6yo{
    margin: 0;
    text-decoration: underline;
}

.Home_a__2Y6yo:hover{
    color:blue;
}

.Home_faqcontainer__2rBvy{
    width: 90%;
    display: flex;
    margin: auto;
    border-radius: 10px;
    flex-direction: column;
    background-color: rgb(245, 245, 245);
    box-shadow: 5px 5px 10px black, -5px -5px 10px black;
    border: 2px solid white;
    margin-bottom: 40px;
}

.Home_h2faq__1OwUT{
    color: blue;
    margin: auto;
    font-size: 42px;
}

.Home_details__2SIaD{
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
    
.Home_container__1H1vc{
    width: 80%;
    display: flex;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
}

.Home_list__1ZXR_{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    margin: auto;
    letter-spacing: 1.35px;
}

.Home_h2__3TJMY{
    font-size: 18px;
}

.Home_detailsButton__16Lnf{
    height: 55px;
    width: 165px;
}

.Home_buttonPage__uz4CQ{
    height: 50px;
    width: 155px;
}

.Home_header__1pDDY{
    text-align: center;
    color: white;
    font-size: 30px;
    letter-spacing: 1.35px;
}

.Home_li__25pqC{
    text-align: left;
    margin: 7px;
}

.Home_a__2Y6yo{
    margin: 0;
    text-decoration: underline;
}

.Home_a__2Y6yo:hover{
    color: blue;
}

.Home_details__2SIaD{
    margin-top: 20px;
    width: 75%;
}

.Home_faqcontainer__2rBvy{
    border-radius: 20px;
    border: none;
    width: 80%;
}

.Home_iconcontainer__mBnNV{
    width: 25%;
}

.Home_iconcontainer__mBnNV img{
    width: 25px;
}
}

@media screen and (max-width: 1000px) {
    
    .Home_container__1H1vc{
        width: 80%;
        display: flex;
        margin: auto;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .Home_list__1ZXR_{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
        font-size: 18px;
        margin: auto;
        letter-spacing: 1.35px;
    }
    
    
    .Home_li__25pqC{
        text-align: left;
        margin: 7px;
    }
    
    .Home_a__2Y6yo{
        color: black;
        margin: 0;
    }
    
    .Home_a__2Y6yo:hover{
        color:blue;
    }
    
    .Home_details__2SIaD{
        margin-top: 20px;
        width: 75%;
    }
    
    .Home_iconcontainer__mBnNV{
        width: 25%;
    }

    .Home_iconcontainer__mBnNV img{
        width: 45px;
    }

    }
.FAQ_container__UJd8d{
    width: 90%;
    height: auto;
    display: flex;
    margin: auto;
    justify-content: space-evenly;
    align-items: flex-end;
}

.FAQ_faqContainer__3Fwgu{
color: blue;
font-size: 18px;
letter-spacing: 1.2px;
font-weight: 500;
width: 65%;
font-family: 'Poppins';
}

.FAQ_iconcontainer__LIlQU{
    justify-content: flex-end;
    display: flex;
    width: 25%;
}

.FAQ_icon__3jBc2{
    width: 30px;
    height: 30px;
    color: blue;
    cursor: pointer;
}

.FAQ_icon__3jBc2:hover{
    scale: 1.15;
}

.FAQ_hr__3TeVH{
border: 1px solid blue;
width: 90%;
}

@media screen and (max-width: 480px) {
    .FAQ_container__UJd8d{
        width: 100%;
    }

    .FAQ_hr__3TeVH{
        width: 100%;
    }

    .FAQ_faqContainer__3Fwgu{
        width: 82%;
    }

    .FAQ_icon__3jBc2{
        width: 25%;
    }
}
.FAQAnswer_container__151UK{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    margin: auto;
}

.FAQAnswer_p__3lBeH{
    font-weight: 500;
}

.Header_container__2ZJN6{
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 65px;
}

.Header_header__1-v5O{
    width: 50%;
    display: flex;
    color: white;
    justify-content: center;
}

.Header_buttonDiv__2QQNl{
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.Header_button__2e36Q{
    padding: 10px;
    font-weight: 600;
    font-family: 'Poppins';
    border-radius: 30px;
    width: 150px;

    background-color: yellow;
    font-size: 20px;
}

.Header_imgDiv__3Bhji{
    width: 15%;
}

.Header_img__3PQe9{
    height: 70px;
    margin-left: 20px;
}

@media screen and (max-width: 480px) {
    .Header_header__1-v5O{
        display: none;
    }
    .Header_container__2ZJN6{
        width: 70%;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    }
    .Header_buttonDiv__2QQNl{
        display: flex;
        justify-content: center;
        width: 40%;
    }
    
    .Header_button__2e36Q{
        padding: 10px;
        font-weight: 600;
        font-family: 'Poppins';
        border-radius: 30px;
        width: 130px;
    
        background-color: yellow;
        font-size: 20px;
    }
    
    .Header_imgDiv__3Bhji{
        width: 100px;
        display: flex;
        justify-content: flex-start;
    }
    
    .Header_img__3PQe9{
        height: 70px;
    }

}

@media screen and (max-width: 1000px) {
    .Header_header__1-v5O{
        display: flex;
    }
    .Header_container__2ZJN6{
        width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 65px;
    }
    .Header_buttonDiv__2QQNl{
        display: flex;
        justify-content: flex-end;
        width: 77%;
    }
    
    .Header_button__2e36Q{
        padding: 10px;
        font-weight: 600;
        font-family: 'Poppins';
        border-radius: 30px;
        width: 150px;
        margin-right: 25px;
        background-color: yellow;
        font-size: 20px;
    }
    
    .Header_imgDiv__3Bhji{
        width: 10%;
        display: flex;
        justify-content: flex-start;
    }
    
    .Header_img__3PQe9{
        height: 60px;
    }

}

@media screen and (max-width: 760px) {
    .Header_header__1-v5O{
        display: none;
    }
}
