.header{
    text-align: center;
    color: blue;
}

.div{
    display: flex;
    margin: auto;
    width: 90%;
    flex-wrap: wrap;
    color: blue;
}

.buttoncontainer{
    display: flex;
    margin: auto;
    justify-content: center;
}

.button{
    background-color: yellow;
    color: black;
    border-radius: 30px;
    font-weight: 600;
    font-size: 20px;
    border: 3px solid black;
    cursor: pointer;
}

.button:hover{
    background-color: black;
    color: yellow;
}

.summarydiv{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    border-radius: 20px;
    color: white;
    padding: 17px;
    width: 100%;
    margin-bottom: 25px;
    background-color: blue;
    box-shadow: 4px 4px 10px rgb(51, 51, 255), -4px -4px 10px rgb(51, 51, 255);
}

.h3header{
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: auto;
}

.hr{
    width: 75%;
    border: .5px solid white;
}

@media screen and (max-width: 480px) {
    
    .summarydiv{
        padding: 5%;
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .h3header{
        font-size: 18px;
    }
}