.div{
    display: flex;
    margin: auto;
    justify-content: flex-start;
    width: 100%;
}

.h3{
    width: 65%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
}

.p{
    font-weight: 500;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (max-width: 480px) {
    
    .h3{
        font-size: 14px;
    }

    .p{
        font-size: 14px;
    }
}