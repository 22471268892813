.container{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    background-color: rgb(0, 0, 255);
    box-shadow: 5px 5px 11px rgb(37, 37, 222), -5px -5px 11px rgb(37, 37, 222);
    margin-bottom: 30px;
}

.button{
    width: 50px;
    margin-right: 50px;
    box-shadow: .2px .2px 1px rgb(0, 32, 135), -.2px -.2px 1px rgb(0, 32, 135);
    border-radius: 10px;
    align-items: center;
    background-color: yellow;
    cursor: pointer;
}

.icon{
    height: 30px;
}

@media screen and (max-width: 480px) {
    
    .container{
        width: 95%;
    }

    .button{
        margin-right: 10px;
    }
}