
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

body{
  font-family: 'Poppins';
  font-weight: 300;
  background-color: blue;
  margin: 0;
}

