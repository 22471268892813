.header{
    color: white;
    font-size: 54px;
    letter-spacing: 1.25px;
    width: 75%;
    justify-content: center;
    display: flex;
    margin: auto;
    text-align: center;
}

.cardcontainer{
    background-color: blue;
    border-radius: 15px;
    width: 480px;
    margin: 25px;
}

.buttonPage{
    background-color: blue;
    color: white;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 10px;
    font-size: 20px;
    width: 160px;
    height: 55px;
    border-radius: 35px;
    cursor: pointer;
}

.buttonPage:hover{
    scale: 1.03;
}

.detailsButton{
    border-radius: 15px;
    background-color: black;
    color: yellow;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 1.3px;
    margin: 10px;
    width: 195px;
    height: 65px;
    font-size: 19px;
    cursor: pointer;
} 

.detailsButton:hover{
    scale: 1.03;
}

.container{
    width: 90%;
    display: flex;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: none;
}

.iconcontainer{
    width: 50px;
    display: flex;
    margin: 5px;
    justify-content: center;
}

.iconcontainer img{
    margin: 9px;
    cursor: pointer;
}

.iconcontainer img:hover{
    scale: 1.11;
}

.list{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 290px;
    font-size: 21px;
    margin: auto;
    color: blue;
    letter-spacing: 1.35px;
    background-color: yellow;
    border-radius: 10px;
    box-shadow: 2px 2px 10px yellow, -2px -2px 10px yellow;
}

.list:hover{
    scale: 1.03;
}

.none{
    display: none;
}


.h2{
    text-align: center;
    font-size: 24px;
    letter-spacing: 1.2px;
}

.li{
    text-align: left;
    margin: 7px;
}

.a{
    margin: 0;
    text-decoration: underline;
}

.a:hover{
    color:blue;
}

.faqcontainer{
    width: 90%;
    display: flex;
    margin: auto;
    border-radius: 10px;
    flex-direction: column;
    background-color: rgb(245, 245, 245);
    box-shadow: 5px 5px 10px black, -5px -5px 10px black;
    border: 2px solid white;
    margin-bottom: 40px;
}

.h2faq{
    color: blue;
    margin: auto;
    font-size: 42px;
}

.details{
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
    
.container{
    width: 80%;
    display: flex;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
}

.list{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    margin: auto;
    letter-spacing: 1.35px;
}

.h2{
    font-size: 18px;
}

.detailsButton{
    height: 55px;
    width: 165px;
}

.buttonPage{
    height: 50px;
    width: 155px;
}

.header{
    text-align: center;
    color: white;
    font-size: 30px;
    letter-spacing: 1.35px;
}

.li{
    text-align: left;
    margin: 7px;
}

.a{
    margin: 0;
    text-decoration: underline;
}

.a:hover{
    color: blue;
}

.details{
    margin-top: 20px;
    width: 75%;
}

.faqcontainer{
    border-radius: 20px;
    border: none;
    width: 80%;
}

.iconcontainer{
    width: 25%;
}

.iconcontainer img{
    width: 25px;
}
}

@media screen and (max-width: 1000px) {
    
    .container{
        width: 80%;
        display: flex;
        margin: auto;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .list{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
        font-size: 18px;
        margin: auto;
        letter-spacing: 1.35px;
    }
    
    
    .li{
        text-align: left;
        margin: 7px;
    }
    
    .a{
        color: black;
        margin: 0;
    }
    
    .a:hover{
        color:blue;
    }
    
    .details{
        margin-top: 20px;
        width: 75%;
    }
    
    .iconcontainer{
        width: 25%;
    }

    .iconcontainer img{
        width: 45px;
    }

    }