.container{
    width: 45%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 25px;
    background-color: white;
    border: .3px solid black;
    box-shadow: 1px 1px 1px black, -1px -1px 1px black;
}

.wrapper{
    display: none;
}

.header{
    color: blue;
    display: flex;
    margin: auto;
    flex-direction: column;
    font-size: 20px;
    justify-content: center;
  }

  .headerprocess{
    color: blue;
    display: flex;
    margin: auto;
    text-align: center;
    font-size: 28px;
    justify-content: center;
  }

  .hr{
    width: 80%;
    border: 1px solid blue;
    margin-top: -15px;
    margin-bottom: 32px;
  }

.buttonProcessContainer{
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
}

.button{
    background-color: yellow;
    color: black;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.2px;
    border-radius: 35px;
    height: 70px;
    margin: 10px;
    font-family: 'Poppins';
    width: 30%;
    border: none;
    cursor: pointer;
    box-shadow: 1px 1px 4px black, -1px -1px 4px black;
}

.button:hover{
    background-color: black;
    color: yellow;
    box-shadow: 3px 3px 4px yellow, -3px -3px 4px yellow;

}

.buttoncontainer{
    display: flex;
    margin: auto;
    justify-content: space-around;
    width: 90%;
}

.buttonprocess{
    background-color: yellow;
    color: black;
    border-radius: 30px;
    font-size: 20px;
    font-family: 'Poppins';
    border: 3px solid black;
    font-weight: 600;
    cursor: pointer;
}

.buttonprocess:hover{
    background-color: black;
    color: yellow;
}

  .inputscontainer{
    display: flex;
    margin: auto;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 25px;
    width: 100%;
  }

  .dropdowncontainer{
    display: flex;
    margin: auto;
    justify-content: center;
    margin-top: 35px;
  }

  .input{
    color: white;
    font-family: "Poppins";
    text-align: right;
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
    background: linear-gradient(145deg, rgb(0, 94, 255), rgb(27, 84, 255));
    border: 1px solid black;
    border-radius: 12px;
    box-shadow: 1px 1px 1px black, -1px -1px 1px black;
    padding: 7px;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}

.h4{
  display: flex;
  margin: auto;
  justify-content: flex-start;
  color: blue;
  font-family: 'Poppins';
}

  @media screen and (max-width: 1000px){
    .container{
      width: 72%;
      margin: auto;
      display: flex;
  }

  .header{
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  
  .wrapper{
      display: none;
  }

    .inputscontainer{
      display: flex;
      margin: auto;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 25px;
      width: 100%;
    }
  
    .input{
      color: white;
      font-family: "Poppins";
      text-align: right;
      margin-left: 15px;
      font-weight: 500;
      font-size: 14px;
      background: linear-gradient(145deg, rgb(0, 94, 255), rgb(27, 84, 255));
      border: 1px solid black;
      border-radius: 12px;
      box-shadow: 1px 1px 1px black, -1px -1px 1px black;
      padding: 7px;
      width: 50%;
  }
  
  .button{
    width: 50%;
  }

  .buttonprocess{
    margin: 10px
  }
  }

  @media screen and (max-width: 480px) {
    .container{
        width: 72%;
        margin: auto;
        display: flex;
    }

    .header{
      text-align: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin: auto;
    }
    
    .wrapper{
        display: none;
    }

      .inputscontainer{
        display: flex;
        margin: auto;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 25px;
        width: 100%;
      }
    
      .input{
        color: white;
        font-family: "Poppins";
        text-align: right;
        margin-left: 15px;
        font-weight: 500;
        font-size: 14px;
        background: linear-gradient(145deg, rgb(0, 94, 255), rgb(27, 84, 255));
        border: 1px solid black;
        border-radius: 12px;
        box-shadow: 1px 1px 1px black, -1px -1px 1px black;
        padding: 7px;
        width: 50%;
    }
    
    .button{
      width: 50%;
    }

    .buttonprocess{
      margin: 10px
    }
}