
.nav{
    width: 100%;
   display: flex;
   margin: auto;
   flex-direction: column;
   align-items: center;
   padding: 10px;
}

.ul{
    list-style: none;
    display: flex;
    margin: auto;
    margin-left: 5px;
    justify-content: flex-end;
}

.li{
    color: white;
    text-decoration: none;
    font-size: 20px;
    padding: 5px;
    margin: 7px;
    font-weight: 500;
}

.li:hover{
    background-color: white;
    color: blue;
    border-radius: 15px;
    padding: 5px;
}

.li:active{
    color: black;
}

@media screen and (max-width: 480px) {
    
    .nav{
        width: 95%;
    }
}
